import React, {
  useContext,
  useState,
} from 'react';
import { NavLink, Link } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import './styles.scss';
import { Context } from '../../context';

export default function Header({ products }) {
  const [menuMobile, setMenuMobile] = useState(false);
  const token = localStorage.getItem('token') || '';
  const { cart } = useContext(Context);
  if (token !== '') {
    const decode = jwtDecode(token);
    const parsedTokenExp = new Date(decode.exp * 1000);
    if (Date.now() > parsedTokenExp) {
      localStorage.removeItem('token');
      window.location.reload();
    }
  }

  function logout() {
    localStorage.removeItem('token');
  }

  const productsLength = () => {
    if (products.length >= 1) {
      return true;
    }
    return false;
  };

  function NavItem({ props }) {
    const [open, setOpen] = useState(false);
    return (
      <li className="nav-items">
        <button type="button" className="user-button" onClick={() => setOpen(!open)}>
          {props}
        </button>

        {open ? <DropdownMenu /> : null}
      </li>
    );
  }

  function DropdownMenu() {
    function DropdownItem() {
      return (
        <>
          <a href="/user-profile" className="menu-item">
            Profile
          </a>
          <a href="/" className="menu-item" onClick={() => logout()}>
            Log out
          </a>

        </>
      );
    }

    return (
      <div className="dropdown">
        <DropdownItem />
      </div>
    );
  }

  let tokenSection = (
    <>
      <div className="row">
        <NavLink exact activeClassName="active" to="/login" onClick={() => setMenuMobile(!menuMobile)}>login</NavLink>
        <NavLink exact activeClassName="active" to="/signup" onClick={() => setMenuMobile(!menuMobile)}>signup</NavLink>
      </div>
    </>
  );
  if (token) {
    const decoded = jwtDecode(token);
    tokenSection = (
      <>
        <div>
          <NavItem props={decoded.role} />
        </div>
      </>

    );
  }

  return (
    <>
      <header>
        <div className={`overlay-menu ${menuMobile ? 'active' : ''}`} onClick={() => setMenuMobile(false)} aria-hidden="true" />
        <div className="content-header">
          <h1>
            <Link className="brand" to="/">
              <img src="/assets/logo-shadow.png" alt="brand" />
            </Link>
          </h1>

          <button className="menu" onClick={() => setMenuMobile(!menuMobile)} type="button">
            <img
              src={menuMobile ? '/assets/cross.svg' : '/assets/menu.svg'}
              alt="menu"
            />
          </button>
          <nav className={`clase ${menuMobile ? 'active' : ''}`}>
            <NavLink exact activeClassName="activeGreen" to="/stickers" onClick={() => setMenuMobile(!menuMobile)}>stickers</NavLink>
            <NavLink exact activeClassName="activeLemon" to="/labels" onClick={() => setMenuMobile(!menuMobile)}>roll labels</NavLink>
            <NavLink exact activeClassName="activeYellow" to="/magnets" onClick={() => setMenuMobile(!menuMobile)}>magnets</NavLink>
            <NavLink exact activeClassName="activeLemon" to="/banners" onClick={() => setMenuMobile(!menuMobile)}>banners</NavLink>
            {productsLength && <NavLink exact activeClassName="activeRed" to="/library" onClick={() => setMenuMobile(!menuMobile)}>library</NavLink> }
            <div>
              {tokenSection}
            </div>
            <hr className="cart-separator" />
            <NavLink exact activeClassName="active" to="/cart" className="cart" onClick={() => setMenuMobile(!menuMobile)}>
              <span>Cart</span>
              <img src="/assets/cart.svg" alt="cart" />
              {cart.itemsCount > 0
                ? <span className="countCart">{cart.itemsCount}</span>
                : null}
            </NavLink>
            <hr className="cart-separator" />
          </nav>
        </div>
      </header>

    </>
  );
}
