import React, { useEffect, useState } from 'react';
import './styles.scss';
import { useParams, useHistory } from 'react-router-dom';
import SelectSizeElement from '../../components/selectSizeElement';
import getCategoriesById from '../../services/category';
import Loader from '../../components/loader';
// import Player from '../../components/player-content';
import TextItemsElement from '../../components/textItemsElement';
import ItemsBoxMuliple from '../../components/itemsBoxMultiple';
// import BannerImg from '../../components/bannerImg';
import DefaultSticker from '../../assets/img/logo-vikings.png';

export default function ElementShop() {
  const { categoryId } = useParams();
  const [error, setError] = useState(false);
  const [stickerSizes, setStickerSizes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState({
    name: '',
    description: '',
    file: '',
  });
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    getCategoriesById({
      idCategory: categoryId,
      query: {
        subcategories: true,
      },
    })
      .then((data) => {
        if (data.subcategories.length !== 0) {
          history.push(`/${categoryId}`);
        }
        if (data.category.isDisabled) {
          history.push('/stickers');
        }
        setCategory(data.category);
        setStickerSizes(data.category.sizes);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  }, [categoryId]);

  let body = (
    <>
      <div className="select-side">
        {!loading && <SelectSizeElement sizes={stickerSizes} />}
      </div>
      <div className="text-element">
        <h5 className="title-cat">{category.name}</h5>
        <p className="down-text-cat">
          {category.description}
        </p>
        <TextItemsElement />
        <ItemsBoxMuliple />
      </div>
    </>
  );

  if (loading) {
    body = (
      <>
        <Loader />
      </>
    );
  }

  if (error) {
    body = (
      <>
        <h3>Error</h3>
      </>
    );
  }

  function renderBackgroundSticker() {
    return category.file
      ? <div className="background" style={{ backgroundImage: `url(${process.env.REACT_APP_API_IMAGE_URL}/${category.file.path})` }} />
      : <div className="background" style={{ backgroundImage: `url(${DefaultSticker})` }} />;
  }

  return (
    <>
      <div className="relative">
        { renderBackgroundSticker() }
        <div className="content-element">
          {body}
        </div>
      </div>
      {/* <Player />
      <BannerImg /> */}
    </>
  );
}
