/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ContactBox from '../contactBox';
import TotalBox from '../totalBox';
import Loader from '../loader';
import BtnCleanCart from '../btnCleanCart';
import { closeCart } from '../../services/cart';
import getShippingPrice from '../../services/shipping';
import './styles.scss';
import { Context } from '../../context';

export default function GridPayment() {
  const { cart } = useContext(Context);
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [zipCode, setZipCode] = useState(cart.data.zipCode ? cart.data.zipCode.toString() : '');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorEmptyField, setErrorEmptyField] = useState(false);
  const [zipCodeError, setZipCodeError] = useState(false);
  const [shippingPrice, setShippingPrice] = useState(0);
  const [shippingError, setShippingError] = useState(false);

  const history = useHistory();

  const useDebounceValue = (input, time = 500) => {
    const [debounceValue, setDebounceValue] = useState(input);

    useEffect(() => {
      const timeout = setTimeout(() => {
        setDebounceValue(input);
      }, time);

      return () => {
        clearTimeout(timeout);
      };
    }, [input, time]);

    return debounceValue;
  };

  const debouncedZipCode = useDebounceValue(zipCode, 500);

  useEffect(() => {
    if (debouncedZipCode.length >= 5 && debouncedZipCode.length <= 9) {
      setZipCodeError(false);
      setShippingError(false);
      getShippingPrice(cart.sessionToken, debouncedZipCode)
        .then((price) => {
          setShippingPrice(price);
        })
        .catch(() => {
          setShippingError(true);
        });
    } else if (debouncedZipCode.length > 0) {
      setZipCodeError(true);
    }
  }, [debouncedZipCode, cart.sessionToken]);

  const confirm = () => {
    setErrorEmail(false);
    setErrorEmptyField(false);
    setZipCodeError(false);

    if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/).test(email)) {
      setErrorEmail(true);
      return;
    }

    if (!address || !zipCode || zipCode.length < 5 || zipCode.length > 9
|| !phone || !name || !city || !state) {
      setErrorEmptyField(true);
      return;
    }

    const userData = {
      email,
      address,
      zipCode,
      phone,
      name,
      city,
      state,
    };

    setLoading(true);
    closeCart(cart.sessionToken, userData)
      .then((resp) => {
        setLoading(false);
        history.push('/pay-confirm');
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };

  let body = (
    <>
      <div className="left-side">
        <div className="box-separate">
          <ContactBox
            setEmail={setEmail}
            setAddress={setAddress}
            address={address}
            zipCode={zipCode}
            setZipCode={setZipCode}
            errorEmail={errorEmail}
            errorEmptyField={errorEmptyField}
            phone={phone}
            name={name}
            city={city}
            state={state}
            setPhone={setPhone}
            setName={setName}
            setCity={setCity}
            setState={setState}
          />
          {zipCodeError && (
          <p className="error-txt-zip">Please enter a valid ZIP code</p>
          )}
          {shippingError && (
          <p className="error-txt-zip">Please enter a valid ZIP code</p>
          )}
        </div>
      </div>
      <div className="right-side">
        <TotalBox shippingPrice={shippingPrice} />
        <div className="box-btns">
          <button className="btn-gral" type="button" onClick={confirm}>
            Confirm & pay
          </button>
          <BtnCleanCart />
        </div>
      </div>
    </>
  );

  if (loading) {
    body = (
      <>
        <Loader />
      </>
    );
  }

  if (error) {
    body = (
      <>
        <h3>Error</h3>
      </>
    );
  }

  return (
    <div className="content-grid-payment">
      {body}
    </div>
  );
}
