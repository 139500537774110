import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Moment from 'react-moment';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import createMessage from '../../services/message';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import './styles.scss';
import fileUpload from '../../services/product';

export default function OrderApproval({ order, changeStatus }) {
  const showButton = order.status === 'APPROVED_BY_ADMIN';
  const { fileAdmin, orderChat } = order;
  const { Messages } = orderChat;
  const [newMessageContent, setNewMessageContent] = useState('');
  const [messageCreated, setMessageCreated] = useState(null);
  const [messageSent, setMessageSent] = useState(false);
  const [preview, setPreview] = useState(null);
  const [uploadedFilePath, setUploadedFilePath] = useState(null);
  const sessionToken = localStorage.getItem('sessionToken') || '';
  const states = [{
    name: 'Pending',
    value: 'PENDING',
  }, {
    name: 'Approved by admin',
    value: 'APPROVED_BY_ADMIN',
  }, {
    name: 'Approved',
    value: 'APPROVED',
  }, {
    name: 'Rejected',
    value: 'REJECTED',
  }, {
    name: 'In print',
    value: 'IN_PRINT',
  }, {
    name: 'Ready to send',
    value: 'READY_TO_SEND',
  }, {
    name: 'Sent',
    value: 'SENT',
  }];

  useEffect(() => {
    if (messageCreated) {
      Messages.push(messageCreated);
      setMessageCreated(null);
    }
  }, [messageCreated]);

  const newMessage = () => {
    let messageContent = newMessageContent;
    if (uploadedFilePath) {
      const fileUrl = `${process.env.REACT_APP_API_IMAGE_URL}/${uploadedFilePath}`;
      messageContent += `<br /><img src="${fileUrl}" alt="Attached Image" style="width:300px; height:auto" />`;
    }

    const newMsg = {
      content: messageContent,
    };
    createMessage(newMsg, orderChat.id, sessionToken)
      .then((msgCreated) => {
        setNewMessageContent('');
        setUploadedFilePath(null);
        setPreview(null);
        setMessageCreated(msgCreated);
        toast.success('Message sent successfully! You\'ll be contacted briefly');
        setMessageSent(true);
        setTimeout(() => {
          setMessageSent(false);
        }, 3000);
      })
      .catch((error) => {
        console.error('Error sending message:', error);
        toast.error('Error sending message');
      });
  };

  const handleFileChange = (e) => {
    const formData = new FormData();
    const file = e.target.files[0];
    formData.append('file', file);
    fileUpload(sessionToken, formData)
      .then((response) => {
        setUploadedFilePath(response.path);
      });
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleFileUploadButtonClick = () => {
    const fileInput = document.getElementById('fileInput');
    fileInput.click();
  };

  const translate = (stateActual) => {
    const actualStateOrder = states.find((state) => state.value === stateActual);
    return actualStateOrder.name;
  };

  return (
    <div>
      <div>
        <h2 className="title-approval">Order Approval</h2>
      </div>
      <div className="approval-page">
        <div className="approval-side">
          <div>
            {fileAdmin.length > 0 ? (
              <Carousel showArrows showThumbs={false} width={300} heigth={300}>
                {fileAdmin.map((item) => (
                  <div key={item.id}>
                    <img
                      className="file-admin"
                      src={`${process.env.REACT_APP_API_IMAGE_URL}${item.path}`}
                      alt=""
                    />
                  </div>
                ))}
              </Carousel>
            ) : <img src="assets/horse.svg" alt="" />}
          </div>
          {preview && (
            <div>
              <h4>Selected File</h4>
              <img src={preview} alt="Preview" className="preview-image" />
            </div>
          )}
          <input type="file" id="fileInput" style={{ display: 'none' }} onChange={handleFileChange} />
          <p>Status:</p>
          <p><b>{translate(order.status)}</b></p>
          {showButton
            ? (
              <button
                type="submit"
                className="btn-gral"
                onClick={changeStatus}
              >
                APPROVE
              </button>
            )
            : null}
        </div>
        <div className="comment-side">
          <div className="comment-box">
            <h2>Commentaries</h2>
            {Messages.length > 0 ? Messages.map((message) => (
              <div className={`message-bubble ${message.fromAdmin ? 'admin-message' : 'user-message'}`} key={message.id}>
                <p>
                  <b>{message.fromAdmin ? 'Admin' : 'User'}</b>
                  {' '}
                  <span>
                    {' '}
                    <Moment format="DD-MM-YYYY - HH:mm">
                      {message.createdAt}
                    </Moment>
                  </span>
                </p>
                <div dangerouslySetInnerHTML={{ __html: message.content }} />
              </div>
            )) : null}
          </div>
          <div className="new-message">
            <input
              className="msg"
              type="text"
              placeholder="Type a message"
              value={newMessageContent}
              onChange={(e) => setNewMessageContent(e.target.value)}
            />
            <button className="button-triangle" type="button" onClick={handleFileUploadButtonClick}>
              <img className="fileup" src="assets/fileup.png" alt="triangle" />
            </button>
            {!messageSent ? (
              <button className="button-triangle" type="button" onClick={newMessage}>
                <img src="assets/Triangle.svg" alt="triangle" />
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
